// i18next-extract-mark-ns-start redsys-alternative

import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground as _IndexBackground} from 'components/landings/IndexBackground';
import {
  ListRow as _ListRow,
  PlansContainer,
  PricingSection,
  SmPlanLabel
} from 'components/pricing/PricingSection';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React, {useEffect, useState} from 'react';
import {HiCheck, HiOutlineX} from 'react-icons/all';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {TrustedBy} from '../components/TrustedBy';
import Bold from '../components/Bold';
import {Button} from '../components/Button';
import dashboard_screenshot_en from '../images/dashboard_screenshot_en.png';
import dashboard_screenshot_es from '../images/dashboard_screenshot_es.png';
import payment_screenshot_en from '../images/mobile_payment_page_english.png';
import payment_screenshot_es from '../images/mobile_payment_page_spanish.png';
import integration from 'images/integration.svg';
import modular from '../images/modular.svg';
import shield from '../images/shield.svg';
import {StyledDiv, StyledImg, StyledP} from '../components/StyledSx';
import {FaqsSection} from '../components/FaqsSection';
import mediamarkt from '../images/mediamarkt_logo.svg';
import telefonica_logo from 'images/telefonica_logo.svg';
import mango from '../images/mango-logo.png';
import marriot from '../images/marriott_Logo.png';
import scalpers from '../images/scalpers_logo.svg';
import trendgraph from '../images/trendgraph.svg';
import {Client} from '../components/Clients';
import {ScreenSizes} from '../../types/responsive';
import {IndexContent} from '../components/landings/IndexContent';
import {Flex} from '../components/Flex';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from '../components/landings/GradientBox';
import rocket from '../images/rocket.svg';
import {JSX} from 'preact';
import selling from 'images/selling.svg';
import visa_logo from '@wp-pay/logos/dist/methods/visa/method-visa-640x360.svg';
import paypal_logo from '@wp-pay/logos/dist/methods/paypal/method-paypal-640x360.svg';
import mastercard_logo from '@wp-pay/logos/dist/methods/mastercard/method-mastercard-640x360.svg';
import googlepay_logo from '@wp-pay/logos/dist/methods/google-pay/method-google-pay-640x360.svg';
import applepay_logo from '@wp-pay/logos/dist/methods/apple-pay/method-apple-pay-640x360.svg';
import bizum_logo from '../images/bizum_payment_gateway.svg';
import mb_way_logo from '@wp-pay/logos/dist/methods/mb-way/method-mb-way-640x360.svg';
import sepa_logo from '@wp-pay/logos/dist/methods/sepa/method-sepa-640x360.svg';
import jcb_logo from '@wp-pay/logos/dist/cards/jcb/card-jcb-logo-_x80.svg';
import dinners_logo from '@wp-pay/logos/dist/cards/diners-club/card-diners-club-logo-_x80.svg';
import discover_logo from '@wp-pay/logos/dist/cards/discover/card-discover-logo-_x80.svg';
import logo from 'images/redsys.svg';
import {GoogleReviews, Place} from '../components/GoogleReviews';
import {Slider} from '../components/Slider';
import {SupportLink} from 'components/links/Support';
import { PrismicPage } from 'components/links/PrismicPage';

const IndexBackground = styled(_IndexBackground)`
  &:before {
    transform: rotate(-64deg) matrix(1, 0, 0, 1, 720, 0);
    height: 2700px;
    width: 2080px;
    left: 75%;
    @media (max-width: ${ScreenSizes.lg}) {
      display: none;
    }
  }
`;

const PlanHeader = styled.div`
  padding: 60px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
`;

export const PlansTable = styled.div`
  position: relative;
  padding: 0 20px 10px 43%;

  @media (max-width: ${ScreenSizes.sm}) {
    padding: 0;
  }
`;

const ListRow = styled(_ListRow)`
  justify-content: space-between;
  padding: 10px 30px;
  margin-right: 30px;

  @media (max-width: ${ScreenSizes.md}) {
    padding: 10px 13px;
    text-align: start;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    font-size: 0.85em;
    gap: 15px;
    > div {
      flex: 3;
    }

    > svg,
    > p {
      flex: 2;
      @media (max-width: ${ScreenSizes.md}) {
        line-height: 1.1rem;
      }
    }
  }
`;

const Plan = styled(StyledDiv)`
  margin: -30px 0 50px;
  position: relative;
  z-index: 1;
  width: 320px;
  flex-shrink: 0;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  border-radius: ${(props) => props.theme.borderRadius};

  @media (max-width: ${ScreenSizes.lg}) {
    width: 290px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    width: 230px;
  }

  @media (max-width: ${ScreenSizes.sm}) {
    width: 65%;
    margin: 50px 0 0;
  }
  @media (max-width: ${ScreenSizes.xs}) {
    width: 80%;
    margin: 50px 0 0;
  }
`;

const PlanLabels = styled.ul`
  padding: 0;
  position: absolute;
  top: 150px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 45%;
  text-align: start;

  li:after {
    display: block;
    content: '';
    width: 20%;
    @media (max-width: ${ScreenSizes.md}) {
      width: 23%;
    }
  }

  @media (max-width: ${ScreenSizes.md}) {
    font-size: 0.85em;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const PlanFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  ${ListRow} {
    justify-content: center;
  }
`;

export const PlansList = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: ${ScreenSizes.sm}) {
    padding: 8px;
    justify-content: initial;
    overflow-x: scroll;
  }
`;

const YesIcon = styled(HiCheck)`
  font-size: 32px;
  color: green;
`;

const NoIcon = styled(HiOutlineX)`
  font-size: 32px;
  color: red;
`;

const DashboardScreenshot = styled.img`
  width: 550px;
  margin-bottom: 0;
  display: block;
  border-radius: 6px;
  box-shadow: 0px 64px 64px rgba(61, 88, 128, 0.05), 0 32px 32px rgba(61, 88, 128, 0.05),
    0 16px 16px rgba(61, 88, 128, 0.05), 0 8px 8px rgba(61, 88, 128, 0.05),
    0 4px 4px rgba(61, 88, 128, 0.05), 0 2px 2px rgba(61, 88, 128, 0.05);
  @media (max-width: ${ScreenSizes.lg}) {
    width: 350px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const ThreeLineListRow = styled.p`
  @media (max-width: 780px) {
    height: 68px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: -20px auto;
  @media (min-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const Dot = styled.div<{active: boolean}>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #d8d8d8;
  ${(props) => props.active && 'background-color: #00c1b1;'}
`;

const clients: Client[] = [
  {
    logo: mediamarkt,
    url: {
      es: 'https://www.mediamarkt.es/',
      de: 'https://www.mediamarkt.de/',
      pt: 'https://www.mediamarkt.pt/'
    },
    alt: 'Mediamarkt payment gateway',
    width: 150,
    scale: 1
  },
  {
    logo: telefonica_logo,
    url: {
      es: 'https://www.telefonica.es/es/',
      en: 'https://www.telefonica.com/en/'
    },
    alt: 'Telefonica',
    width: 150,
    scale: 1
  },
  {
    logo: mango,
    url: {
      es: 'https://shop.mango.com/es',
      fr: 'https://shop.mango.com/fr',
      de: 'https://shop.mango.com/de',
      en: 'https://shop.mango.com/gb',
      pt: 'https://shop.mango.com/pt',
      it: 'https://shop.mango.com/it'
    },
    alt: 'Mango payment gateway',
    width: 150,
    scale: 1
  },
  {
    logo: marriot,
    url: {
      es: 'https://www.espanol.marriott.com',
      fr: 'https://www.marriott.fr',
      pt: 'https://www.marriott.com.br',
      de: 'https://www.marriott.de',
      it: 'https://www.marriott.it',
      en: 'https://www.marriott.com'
    },
    alt: 'Marriot payment gateway',
    width: 150,
    scale: 0.9
  },
  {
    logo: scalpers,
    url: {
      es: 'https://www.scalpers.com/',
      pt: 'https://pt.scalperscompany.com/',
      fr: 'https://fr.scalperscompany.com/',
      de: 'https://de.scalperscompany.com/',
      en: 'https://en.eu.scalperscompany.com/'
    },
    alt: 'Scalpers payment gateway',
    width: 150,
    scale: 1
  }
];

const paymentMethods: JSX.Element[] = [
  <StyledImg src={visa_logo} alt="Visa" title="Visa" />,
  <StyledImg src={paypal_logo} alt="PayPal" title="PayPal" />,
  <StyledImg src={mastercard_logo} alt="Mastercard" title="Mastercard" />,
  <StyledImg src={googlepay_logo} alt="Google Pay" title="Google Pay" />,
  <StyledImg src={applepay_logo} alt="Apple Pay" title="Apple Pay" />,
  <StyledImg src={bizum_logo} alt="Bizum" title="Bizum" />,
  <StyledImg src={mb_way_logo} alt="MB Way" title="MB Way" />,
  <StyledImg src={sepa_logo} alt="SEPA" title="SEPA" />,
  <StyledImg src={jcb_logo} alt="JCB" title="JCB" />,
  <StyledImg
    src={dinners_logo}
    alt="Dinners Club"
    title="Dinners Club"
    sx={{paddingRight: '20px'}}
  />,
  <StyledImg
    src={discover_logo}
    alt="Discover payment gateway"
    title="Discover payment gateway"
    sx={{paddingLeft: '20px'}}
  />
];

const ReviewsBackground = styled.div`
  position: relative;
  margin: 100px 0;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Page: React.FC<PageProps> = (props) => {
  const {t, navigate, language} = useI18next();
  const dashboardScreenshot = language === 'es' ? dashboard_screenshot_es : dashboard_screenshot_en;
  const paymentScreenshot = language === 'es' ? payment_screenshot_es : payment_screenshot_en;
  const [activePlan, setActivePlan] = useState<'monei' | 'redsys'>('monei');
  const faqs = [
    {
      header: t('Why do I need an alternative to Redsys?'),
      text: t(
        'Redsys is a good solution if you only need to accept a few payment methods, but if you want to satisfy more customers and boost e-commerce conversions, it’s vital to accept alternative and local payment methods. With Redsys, you would need multiple third-party integrations to do this. But with an alternative like MONEI, all you need is one integration to set up a wide range of payment methods (and benefit from a variety of other built-in features).'
      ),
      content: (
        <Trans>
          Redsys is a good solution if you only need to accept a few payment methods, but if you
          want to satisfy more customers and{' '}
          <BlogLink slug="ecommerce-conversion-rate-tips-to-help-you-sell-more">
            boost e-commerce conversions
          </BlogLink>
          , it’s vital to accept <BlogLink slug="alternative-payment-methods">alternative</BlogLink>{' '}
          and <BlogLink slug="local-payment-methods">local payment methods</BlogLink>. With Redsys,
          you would need multiple third-party integrations to do this. But with an alternative like{' '}
          <AnchorLink href="https://docs.monei.com/docs/e-commerce/redsys/">MONEI</AnchorLink>, all
          you need is one integration to set up a wide range of payment methods (and benefit from a
          variety of other built-in <InternalPageLink slug="features">features</InternalPageLink>).
        </Trans>
      )
    },
    {
      header: t('Is it easy to switch to MONEI as an alternative to Redsys?'),
      text: t(
        'Absolutely. MONEI connects with all the most popular e-commerce platforms, or use our Payments API to integrate with your custom-built website.'
      ),
      content: (
        <Trans>
          Absolutely. MONEI connects with all the most popular{' '}
          <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
            e-commerce platforms
          </PrismicPage>{' '}
          , or use our{' '}
          <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API</AnchorLink> to
          integrate with your custom-built website.
        </Trans>
      )
    },
    {
      header: t('What countries does MONEI support?'),
      text: t(
        'MONEI supports merchants in Spain and Andorra and helps you grow your business internationally by accepting more local payment methods. We’re growing — the next few countries on our roadmap are Portugal, France, and Italy. If you’d like to get notified when MONEI is available in your country, subscribe to our newsletter.'
      ),
      content: (
        <Trans>
          <AnchorLink href="https://support.monei.com/hc/articles/360017953338">
            MONEI supports
          </AnchorLink>{' '}
          merchants in <InternalPageLink slug="payment-gateway-spain">Spain</InternalPageLink> and{' '}
          <InternalPageLink slug="andorra-payment-gateway">Andorra</InternalPageLink> and helps you
          grow your business internationally by accepting more local payment methods. We’re growing
          — the next few countries on our roadmap are Portugal, France, and Italy. If you’d like to
          get notified when MONEI is available in your country,{' '}
          <InternalPageLink slug="subscribe">subscribe to our newsletter</InternalPageLink>.
        </Trans>
      )
    },
    {
      header: t('Does using an alternative to Redsys like MONEI affect the checkout process?'),
      text: t(
        'Yes. But in a positive way. Accepting more payment methods leads to increased conversions and with the prebuilt checkout page you can customize the checkout with your logo, branding, and company URL so customers are never redirected to a third-party domain.'
      ),
      content: (
        <Trans>
          Yes. But in a positive way. Accepting more payment methods leads to increased conversions
          and with the{' '}
          <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
            prebuilt checkout page
          </AnchorLink>{' '}
          you can{' '}
          <AnchorLink href="https://support.monei.com/hc/articles/360017814717">
            customize
          </AnchorLink>{' '}
          the checkout with your logo, branding, and company URL so customers are never redirected
          to a third-party domain.
        </Trans>
      )
    },
    {
      header: t('Can I use MONEI and Redsys at the same time?'),
      text: t(
        'Yes, but it’s not necessary. MONEI supports all the same payment methods and more like Bizum, cards, Apple Pay, Google Pay, Click to Pay, PayPal, MB WAY, and Bancontact (view them all here). MONEI is the only payment gateway you need.'
      ),
      content: (
        <Trans>
          Yes, but it’s not necessary. MONEI supports all the same payment methods and more like{' '}
          <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/cards">cards</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/click-to-pay">Click to Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>, and{' '}
          <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>, (view
          them all <InternalPageLink slug="payment-methods">here</InternalPageLink>). MONEI is the
          only payment gateway you need.
        </Trans>
      )
    },
    {
      header: t('Is MONEI a secure Redsys alternative?'),
      text: t(
        'Yes, with MONEI your business and customer payment information is always secure. MONEI is PCI DSS and PSD2 compliant, uses tokenization technology, and supports 3D Secure checkout.'
      ),
      content: (
        <Trans>
          Yes, with MONEI your business and customer payment information is always secure. MONEI is{' '}
          <InternalPageLink slug="pci-dss">PCI DSS</InternalPageLink> and{' '}
          <BlogLink slug="psd2">PSD2</BlogLink> compliant, uses{' '}
          <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
            tokenization
          </BlogLink>{' '}
          technology, and supports{' '}
          <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
            3D Secure checkout
          </BlogLink>
          .
        </Trans>
      )
    },
    {
      header: t('Does choosing MONEI as an alternative to Redsys affect customer data?'),
      text: t(
        'With Redsys, you can only see a snapshot of payment history, whereas from your MONEI Dashboard you can view and analyze detailed, real-time payment data to improve operations, measure marketing campaigns, and find the exact reason why some payments fail.'
      ),
      content: (
        <Trans>
          With Redsys, you can only see a snapshot of payment history, whereas from your{' '}
          <AnchorLink href="https://dashboard.monei.com/">MONEI Dashboard</AnchorLink> you can view
          and analyze detailed, real-time payment data to improve operations, measure marketing
          campaigns, and find the exact reason why some payments fail.
        </Trans>
      )
    },
    {
      header: t('How does customer support compare between Redsys and an alternate like MONEI?'),
      text: t(
        'If you use Redsys to sell online you need to go to your bank branch and speak to a salesperson to get a virtual POS. The level of service varies depending on the bank, but generally, you don’t get the same level of technical support or payments knowledge that you can get from MONEI.  We offer personalized support and in some cases, a dedicated Slack channel.'
      ),
      content: (
        <Trans>
          If you use Redsys to sell online you need to go to your bank branch and speak to a
          salesperson to get a virtual POS. The level of service varies depending on the bank, but
          generally, you don’t get the same level of technical support or payments knowledge that
          you can get from MONEI. We offer personalized{' '}
          <AnchorLink href="https://support.monei.com/hc/">support</AnchorLink> and in some cases, a
          dedicated Slack channel.
        </Trans>
      )
    },
    {
      header: t('Does MONEI offer physical payment services too?'),
      text: t('Yes. Use MONEI Pay to accept contactless in-person payments from your smartphone.'),
      content: (
        <Trans>
          Yes. Use <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> to accept
          contactless in-person payments from your smartphone.
        </Trans>
      )
    },
    {
      header: t('Aside from Redsys, how does MONEI compare to other payment solutions?'),
      text: t('Learn how MONEI compares to more payment service providers here.'),
      content: (
        <Trans>
          Learn how MONEI compares to more payment service providers{' '}
          <InternalPageLink slug="page/compare-payment-gateways">here</InternalPageLink>.
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  useEffect(() => {
    const plansList = document.getElementById('plans-list') as HTMLElement;
    const redsysPlan = document.getElementById('redsys-plan') as HTMLElement;
    plansList.addEventListener('scroll', () => {
      const redsysPlanRects = redsysPlan.getBoundingClientRect();
      if (redsysPlanRects.x < window.innerWidth / 2) {
        setActivePlan('redsys');
      } else {
        setActivePlan('monei');
      }
    });
  }, []);

  return (
    <>
      <SEO
        path="redsys-alternative"
        title={t('Redsys Alternative')}
        description={t(
          'Looking for the best Redsys alternative? Accept Bizum and more payment methods from a single platform. Pay less on transaction fees. Join MONEI now ››'
        )}
        schema={schemaFaqs}
      />
      <IndexBackground>
        <Content>
          <Section sx={{paddingTop: '60px', paddingBottom: '0'}}>
            <IndexContent sx={{maxWidth: 'unset'}}>
              <SectionHeader underline tagName="h1">
                <Trans>Redsys Alternative</Trans>
              </SectionHeader>
              <Trans parent="p">
                Searching for an <Bold>alternative to Redsys</Bold>? Look no further. the right
                place.
              </Trans>
              <Trans parent="p">
                Use <Bold>MONEI</Bold> to accept Bizum and various other alternative payment
                methods,<Bold> all from one central platform.</Bold>
              </Trans>
              <Trans parent="p">
                Register today to <Bold>tailor your checkout process</Bold> and{' '}
                <Bold>enhance conversion rates</Bold> through a single integration.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <StyledDiv>
              <IndexImage
                top="60px"
                width="700px"
                src={selling}
                alt="Redsys Alternative"
                title="Redsys Alternative"
                className="hide-on-mobile"
              />
            </StyledDiv>
          </Section>
          <StyledDiv sx={{marginBottom: '50px'}}>
            <TrustedBy clientList={clients} />
          </StyledDiv>
        </Content>
      </IndexBackground>

      <Content>
        <Section centered column sx={{textAlign: 'center', maxWidth: '1280px'}}>
          <SectionHeader underline>
            <Trans>Why MONEI is the best Redsys alternative</Trans>
          </SectionHeader>
          <Slider
            items={paymentMethods}
            options={{
              perView: {all: 5, sm: 3, xs: 2},
              autoplay: true,
              loop: true,
              spacing: {all: 40, md: 20, sm: 10}
            }}
            sx={{overflow: 'unset'}}
            slideHeight={150}
          />
          <Flex
            direction="column"
            gap={15}
            sx={{maxWidth: '1024px', marginTop: '50px'}}
            alignItems="center">
            <SectionHeader>
              <Trans>Accept Bizum, Apple Pay, Google Pay, and more with a single integration</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Redsys, you can only accept cards and Bizum. If you want to accept more local and
              <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>,
              you need multiple payment gateway integrations.
            </Trans>
            <Trans parent="p">
              With MONEI as your Redsys alternative, you can{' '}
              <Bold>reach more customers, outshine the competition, and increase sales</Bold> by
              accepting <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>{' '}
              <Bold>and</Bold>{' '}
              <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
              <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
              <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>, and
              more APMs like{' '}
              <InternalPageLink slug="payment-methods/click-to-pay">Click to Pay</InternalPageLink>,{' '}
              <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink> and{' '}
              <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>.
              <Bold>all from one integration</Bold>.
            </Trans>
            <Button
              variant="dark"
              onClick={() => navigate('/payment-methods/')}
              style={{marginTop: 20}}>
              <Trans>View All Payment Methods</Trans>
            </Button>
          </Flex>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section sx={{alignItems: 'center', gap: '30px', paddingTop: {lg: '8%', sm: '20%'}}}>
            <SectionImage
              style={{width: '70%'}}
              mobileWidth={370}
              src={paymentScreenshot}
              alt="Scan to Pay"
              title="Scan to Pay"
              className="hide-on-mobile"
            />
            <div>
              <SectionHeader>
                <Trans>Build trust with a customized checkout experience</Trans>
              </SectionHeader>
              <Trans parent="p">
                Integrating your e-commerce store with Redsys involves third-party redirects —
                customers see a Redsys URL on the payment page, instead of yours. This compromises
                trust and leads to more abandoned carts.
              </Trans>
              <Trans parent="p">
                With MONEI, you can <Bold>increase conversions</Bold> by{' '}
                <Bold> customizing the appearance of your payment page.</Bold> Use your brand
                colors, logo, and your company's unique domain name so customers stay on your site
                during the entire checkout process.
              </Trans>
              <SignUpButton variant="dark">
                <Trans>Get MONEI</Trans>
              </SignUpButton>
            </div>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section sx={{alignItems: 'center'}}>
          <StyledDiv>
            <SectionHeader underline>
              <Trans>Save money on integration fees</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Redsys, the payment options are limited. You need to spend money on additional
              integrations to accept more payment methods.
            </Trans>
            <Trans parent="p">
              With MONEI, you’ll experience the
              <Bold> convenience</Bold> and <Bold>cost-saving</Bold> benefits of managing your
              entire payment stack with{' '}
              <Bold>
                <AnchorLink
                  href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/"
                  external>
                  just one integration
                </AnchorLink>
              </Bold>
              .
            </Trans>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </StyledDiv>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={integration} alt="Pay less" title="Pay less" sx={{width: '45%'}} />
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg
              src={modular}
              alt="Get built-in virtual POS services"
              title="Get built-in virtual POS services"
              sx={{width: '40%'}}
            />
          </StyledDiv>
          <StyledDiv>
            <SectionHeader>
              <Trans>Get built-in virtual POS services</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Redsys, you have to get a virtual POS or merchant ID from an acquiring bank
              before you can integrate with its payment gateway to accept online payments.
            </Trans>
            <Trans parent="p">
              With MONEI, <Bold>these services are built-in.</Bold> Skip the headache of requesting
              a <InternalPageLink slug="tpv-virtual">virtual POS</InternalPageLink> from the bank.
              All you have to do is <Bold>create your MONEI account</Bold> and you'll be equipped in
              no time <Bold>to take e-commerce payments.</Bold>
            </Trans>
            <SignUpButton variant="light">
              <Trans>Get Started</Trans>
            </SignUpButton>
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section>
          <StyledDiv>
            <SectionHeader>
              <Trans>Maximize your business operations with real-time payment insights</Trans>
            </SectionHeader>
            <Trans parent="p">
              With Redsys, you can view payment history snapshots from the last 30 days or year, but
              you can't filter immediate payment data to inform business decisions.
            </Trans>
            <Trans parent="p">
              <Bold>Gain insights</Bold> into marketing campaign results, get{' '}
              <Bold>accurate information </Bold>
              about failed transactions, and{' '}
              <Bold>identify strategies for payment optimization and sales growth</Bold> through the
              comprehensive, real-time online payment data in your{' '}
              <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                MONEI Dashboard
              </AnchorLink>
              .
            </Trans>
            <SignUpButton variant="light">
              <Trans>Get MONEI</Trans>
            </SignUpButton>
          </StyledDiv>
          <DashboardScreenshot
            src={dashboardScreenshot}
            alt="Maximize your business operations using real-time payment insights"
            title="Maximize your business operations using real-time payment insights"
          />
        </Section>
      </Content>

      <Content>
        <Section>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg
              src={shield}
              alt="Secure payments for merchants and customers"
              title="Secure payments for merchants and customers"
              sx={{width: '25%'}}
            />
          </StyledDiv>
          <div>
            <SectionHeader>
              <Trans>Secure payments for merchants and customers</Trans>
            </SectionHeader>
            <Trans parent="p">
              Just like Redsys, MONEI is{' '}
              <AnchorLink href="https://monei.com/pci-dss/">PCI compliant</AnchorLink>, provides{' '}
              <Bold>3D Secure checkout</Bold> capabilities, and applies <Bold>tokenization</Bold> to
              encrypt sensitive{' '}
              <AnchorLink href="https://docs.monei.com/docs/guides/save-payment-method/">
                payment information
              </AnchorLink>
              .
            </Trans>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>

      <Content>
        <Section>
          <StyledDiv>
            <SectionHeader>
              <Trans>Boost your transaction approval rate </Trans>
            </SectionHeader>
            <Trans parent="p">
              Redsys doesn't offer the flexibility to enhance your transaction approval rate through
              the addition of multiple payment processors.
            </Trans>
            <Trans parent="p">
              With MONEI's{' '}
              <InternalPageLink slug="features/payments-orchestration">
                payments orchestration platform (POP)
              </InternalPageLink>
              , not only can you integrate various payment processors, but you can also establish
              routing rules directing payments to the top-performing processor.
            </Trans>
            <Trans parent="p">
              The outcome? Minimized payment failures and amplified conversions. Opt for{' '}
              <InternalPageLink slug="pricing">MONEI PLUS</InternalPageLink> to access this payments
              orchestration feature.
            </Trans>
            <ContactSalesButton variant="dark" />
          </StyledDiv>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg
              src={trendgraph}
              alt="Boost your transaction approval rate"
              title="Boost your transaction approval rate"
              sx={{width: '40%'}}
            />
          </StyledDiv>
        </Section>
      </Content>

      {/* i18next-extract-mark-ns-start alternatives */}
      <Content>
        <Section column sx={{textAlign: 'center', gap: '30px'}}>
          <SectionHeader underline>
            <Trans>Compare MONEI to Redsys</Trans>
          </SectionHeader>
          <PricingSection>
            <Dots>
              <Dot active={activePlan === 'monei'} />
              <Dot active={activePlan === 'redsys'} />
            </Dots>
            <PlansTable>
              <PlanLabels>
                <Trans parent={ListRow}>
                  <SupportLink slug="/articles/360017814717">Customizable checkout</SupportLink>{' '}
                  (branding and domain)
                </Trans>
                <ListRow sx={{height: '75px'}}>
                  <Trans>
                    <SupportLink slug="/articles/360017954318">Transaction fees</SupportLink>
                  </Trans>
                </ListRow>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="payment-methods/credit-cards">
                    Credit cards
                  </InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>
                  APM's:{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/apple-pay">Apple pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/bancontact">Bancontact</InternalPageLink>,
                  and more
                </Trans>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>{' '}
                  (one-click payments)
                </Trans>
                <Trans parent={ListRow}>One-day payout</Trans>
                <Trans parent={ListRow}>Real-time payments dashboard and analytics</Trans>
                <Trans parent={ListRow}>Abandoned orders tracking</Trans>
                <Trans parent={ListRow}>Expert payments support</Trans>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="features/payments-orchestration">
                    Payments orchestration
                  </InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>
                  <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>
                  Send payment requests and receipts via{' '}
                  <InternalPageLink slug="features/whatsapp-payments">WhatsApp</InternalPageLink>
                </Trans>
                <Trans parent={ListRow}>
                  Smartphone in-person payments with{' '}
                  <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>
                </Trans>{' '}
                <Trans parent={ListRow}>
                  <InternalPageLink slug="pci-dss">PCI Compliance</InternalPageLink> and{' '}
                  <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                    3D Secure
                  </BlogLink>{' '}
                  checkout
                </Trans>
              </PlanLabels>
              <PlansContainer>
                <PlansList id="plans-list">
                  <Plan id="monei-plan">
                    <PlanHeader>
                      <img src="https://assets.monei.com/images/logo.svg" alt="MONEI" />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017814717">
                            Customizable checkout
                          </SupportLink>{' '}
                          (branding and domain)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow sx={{height: '75px'}}>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017954318">Transaction fees</SupportLink>
                        </Trans>
                        <StyledP
                          sx={{
                            fontSize: {lg: '0.85em'},
                            lineHeight: {lg: '1.2em'},
                            textAlign: 'center'
                          }}>
                          <Trans>Dynamic rate — pay less as you sell more</Trans>
                        </StyledP>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/credit-cards">
                            Credit cards
                          </InternalPageLink>
                        </Trans>
                        <ThreeLineListRow>
                          <YesIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                        </Trans>
                        <ThreeLineListRow>
                          <YesIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          APM's:{' '}
                          <InternalPageLink slug="payment-methods/google-pay">
                            Google pay
                          </InternalPageLink>
                          ,{' '}
                          <InternalPageLink slug="payment-methods/apple-pay">
                            Apple pay
                          </InternalPageLink>
                          ,{' '}
                          <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>,{' '}
                          <InternalPageLink slug="payment-methods/bancontact">
                            Bancontact
                          </InternalPageLink>
                          , and more
                        </Trans>
                        <ThreeLineListRow>
                          <YesIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/click-to-pay">
                            Click to Pay
                          </InternalPageLink>{' '}
                          (one-click payments)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>One-day payout</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Real-time payments dashboard and analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Abandoned orders tracking</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Expert payments support</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="features/payments-orchestration">
                            Payments orchestration
                          </InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="features/pay-by-link">
                            Pay By Link
                          </InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Send payment requests and receipts via{' '}
                          <InternalPageLink slug="features/whatsapp-payments">
                            WhatsApp
                          </InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Smartphone in-person payments with{' '}
                          <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="pci-dss">PCI Compliance</InternalPageLink> and{' '}
                          <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                            3D Secure
                          </BlogLink>{' '}
                          checkout
                        </Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                  <Plan id="redsys-plan">
                    <PlanHeader>
                      <img src={logo} alt="Redsys Payments" width={240} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017814717">
                            Customizable checkout
                          </SupportLink>{' '}
                          (branding and domain)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow sx={{height: '75px'}}>
                        <Trans parent={SmPlanLabel}>
                          <SupportLink slug="/articles/360017954318">Transaction fees</SupportLink>
                        </Trans>
                        <StyledP
                          sx={{
                            fontSize: {lg: '0.85em'},
                            lineHeight: {lg: '1.2em'},
                            textAlign: 'center'
                          }}>
                          <Trans>Fixed rate regardless of sales volume</Trans>
                        </StyledP>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/credit-cards">
                            Credit cards
                          </InternalPageLink>
                        </Trans>
                        <ThreeLineListRow>
                          <YesIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                        </Trans>
                        <ThreeLineListRow>
                          <YesIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          APM's:{' '}
                          <InternalPageLink slug="payment-methods/google-pay">
                            Google pay
                          </InternalPageLink>
                          ,{' '}
                          <InternalPageLink slug="payment-methods/apple-pay">
                            Apple pay
                          </InternalPageLink>
                          ,{' '}
                          <InternalPageLink slug="payment-methods/mb-way">MB WAY</InternalPageLink>,{' '}
                          <InternalPageLink slug="payment-methods/bancontact">
                            Bancontact
                          </InternalPageLink>
                          , and more
                        </Trans>
                        <ThreeLineListRow>
                          <NoIcon />
                        </ThreeLineListRow>
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="payment-methods/click-to-pay">
                            Click to Pay
                          </InternalPageLink>{' '}
                          (one-click payments)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>One-day payout</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Real-time payments dashboard and analytics
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Abandoned orders tracking</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>Expert payments support</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="features/payments-orchestration">
                            Payments orchestration
                          </InternalPageLink>
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="features/pay-by-link">
                            Pay By Link
                          </InternalPageLink>
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Send payment requests and receipts via{' '}
                          <InternalPageLink slug="features/whatsapp-payments">
                            WhatsApp
                          </InternalPageLink>
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          Smartphone in-person payments with{' '}
                          <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={SmPlanLabel}>
                          <InternalPageLink slug="pci-dss">PCI Compliance</InternalPageLink> and{' '}
                          <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                            3D Secure
                          </BlogLink>{' '}
                          checkout
                        </Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                </PlansList>
              </PlansContainer>
            </PlansTable>
          </PricingSection>
        </Section>
      </Content>

      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '60px'}}
          contentStyle={{maxWidth: {all: '600px', md: '100%'}}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Grow your business faster with the best Redsys alternative</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Sign up for MONEI today to get all your payment needs from a single platform.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              bottom: '-60px',
              right: '0',
              width: '33%'
            }}
            src={rocket}
          />
        </GradientBox>
      </Content>

      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Redsys alternative FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {in: ["common", "redsys-alternative", "alternatives"]}
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
